<template>
  <div class="header">
    <div class="container">
      <div class="header-warpper">
        <div class="left">
          <div class="logo" @click="goHome">
            <img src="~@/assets/images/logo.png" alt="" />
          </div>
          <div class="nav-warpper" ref="nav">
            <div class="nav">
              <div class="nav-item">
                <div class="logo" @click="goHome">
                  <img src="~@/assets/images/logo.png" alt="" />
                </div>
                <div class="close" @click="close">
                  <img src="~@/assets/images/close.png" alt="" />
                </div>
              </div>
              <div :class="currentIndex !== 0 ? 'nav-item' : 'nav-item current'">
                <div class="location">
                  <span class="info" @click="navClick(0)">Location</span>
                  <img src="~@/assets/images/arr_b.png" alt="" />
                  <img class="img" src="~@/assets/images/arr_bottom.png" alt="" @click.self="handleLocation"
                    v-show="!locationShow" />
                  <img class="img" src="~@/assets/images/arr_t.png" alt="" @click.self="handleLocation"
                    v-show="locationShow" />
                </div>
                <div class="sub-menu" @mouseleave="itemMouseOut" ref="sub">
                  <div :class="item.id == pageId
                    ? 'sub-menu-item current'
                    : 'sub-menu-item'
                    " v-for="(item, index) in locationList" :key="item.id">
                    <div class="label">
                      <div @click="goPage(item, item.id)" @mousemove="itemMouseMove(index)">
                        {{ item.name }}
                      </div>
                      <span class="sf-sub-indicator"><i class="fa fa-angle-right icon-in-menu"></i></span>
                      <img class="img" src="~@/assets/images/arr_bottom.png" alt="" v-if="!item.show"
                        @click.self="itemClick(index)" />
                      <img class="img" src="~@/assets/images/arr_t.png" alt="" v-if="item.show"
                        @click.self="itemClick(index)" />
                    </div>
                    <ul class="sub-sub-menu" v-show="item.show || moveIndex === index">
                      <li :class="i.id == cId
                        ? 'sub-sub-menu-item current'
                        : 'sub-sub-menu-item'
                        " v-for="i in item.children" :key="i.id">
                        <div @click.stop="goPage(i, item.id, i.id)">
                          {{ i.name }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div :class="currentIndex !== 1 ? 'nav-item' : 'nav-item current'" @click="navClick(1)">
                <span class="info"> About Us</span>
              </div>
              <div :class="currentIndex !== 2 ? 'nav-item' : 'nav-item current'" @click="navClick(2)">
                <span class="info">FAQs</span>
              </div>
              <div :class="currentIndex !== 4 ? 'nav-item' : 'nav-item current'">
                <div class="location">
                  <span class="info">We Buy Cars</span>
                  <img src="~@/assets/images/arr_b.png" alt="" />
                  <img class="img" src="~@/assets/images/arr_bottom.png" alt="" @click.self="handleLocation1"
                    v-show="!locationShow1" />
                  <img class="img" src="~@/assets/images/arr_t.png" alt="" @click.self="handleLocation1"
                    v-show="locationShow1" />
                </div>
                <div class="sub-menu" @mouseleave="itemMouseOut" ref="sub1">
                  <div :class="item.id == pageId1
                    ? 'sub-menu-item current'
                    : 'sub-menu-item'
                    " v-for="(item, index) in locationList1" :key="item.id">
                    <div class="label">
                      <div @click="goPage1(item, item.id)" @mousemove="itemMouseMove1(index)">
                        {{ item.name }}
                      </div>
                      <span class="sf-sub-indicator"><i class="fa fa-angle-right icon-in-menu"></i></span>
                    </div>
                  </div>
                </div>
              </div>
              <div :class="currentIndex !== 5 ? 'nav-item' : 'nav-item current'" @click="navClick(5)">
                <span class="info">Blogs</span>
              </div>
              <div :class="currentIndex !== 3 ? 'nav-item' : 'nav-item current'" @click="navClick(3)">
                <span class="info">Get Started</span>
              </div>
              <div class="nav-item">
                <a href="tel:1300 849 879">
                  <i class="fa fa-phone"></i>&nbsp; 1300 849 879
                </a>
              </div>
            </div>
          </div>
          <a href="tel:1300 849 879">
            <div class="phone">
              <i class="fa fa-phone"></i>&nbsp; 1300 849 879
            </div>
          </a>
        </div>
        <div class="right" @click="goStart">Get Started</div>
        <div class="right-button" @click="handleSide">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageId: {
      default: 0,
    },
    cId: {
      default: 0,
    },
    pageId1: {
      default: 0,
    },
    cId1: {
      default: 0,
    },
  },
  data() {
    return {
      locationList: [
        {
          name: "Australian Capital Territory",
          id: 1,
          show: false,
          children: [
            {
              id: 1,
              name: "Canberra",
            },
            {
              id: 2,
              name: "Barton",
            },
            {
              id: 3,
              name: "Yarralumla",
            },
            {
              id: 4,
              name: "Forrest",
            },
            {
              id: 5,
              name: "Deakin",
            },
            {
              id: 6,
              name: "Red Hill",
            },
            {
              id: 7,
              name: "Hughes",
            },
            {
              id: 8,
              name: "Kambah",
            },
            {
              id: 9,
              name: "Binalong",
            },
            {
              id: 10,
              name: "Sutton",
            },
            {
              id: 11,
              name: "Bookham",
            },
            {
              id: 12,
              name: "Lyons",
            },
            {
              id: 13,
              name: "Bowning",
            },
            {
              id: 14,
              name: "Murrumbateman",
            },
            {
              id: 15,
              name: "Hall",
            },
          ],
        },
        {
          name: "New South Wales",
          id: 2,
          show: false,
          children: [
            {
              id: 1,
              name: "Albury",
            },
            {
              id: 2,
              name: "Armidale",
            },
            {
              id: 3,
              name: "Central Coast",
            },
            {
              id: 4,
              name: "Coffs Harbour",
            },
            {
              id: 5,
              name: "Dubbo",
            },
            {
              id: 6,
              name: "Lismore",
            },
            {
              id: 7,
              name: "Maitland",
            },
            {
              id: 8,
              name: "Orange",
            },
            {
              id: 9,
              name: "Newcastle",
            },
            {
              id: 10,
              name: "Port Macquarie",
            },
            {
              id: 11,
              name: "Sydney",
            },
            {
              id: 12,
              name: "Tamworth",
            },
            {
              id: 13,
              name: "Wagga Wagga",
            },
            {
              id: 14,
              name: "Wollongong",
            },
          ],
        },
        {
          name: "Northern Territory",
          id: 3,
          show: false,
          children: [
            {
              id: 1,
              name: "Alice Springs",
            },
            {
              id: 2,
              name: "Darwin",
            },
            {
              id: 3,
              name: "Galiwinku",
            },
            {
              id: 4,
              name: "Jabiru",
            },
            {
              id: 5,
              name: "Katherine",
            },
            {
              id: 6,
              name: "Litchfield",
            },
            {
              id: 7,
              name: "Maningrida",
            },
            {
              id: 8,
              name: "Nguiu",
            },
            {
              id: 9,
              name: "Nhulunbuy",
            },
            {
              id: 10,
              name: "Palmerston-East Arm",
            },
            {
              id: 11,
              name: "Port Keats",
            },
            {
              id: 12,
              name: "Tennant Creek",
            },
            {
              id: 13,
              name: "Wadeye/Victoria-Daly",
            },
            {
              id: 14,
              name: "Yulara",
            },
          ],
        },
        {
          name: "Queensland",
          id: 4,
          show: false,
          children: [
            {
              id: 1,
              name: "Beenleigh",
            },
            {
              id: 2,
              name: "Brisbane",
            },
            {
              id: 3,
              name: "Cairns",
            },
            {
              id: 4,
              name: "Gold Coast",
            },
            {
              id: 5,
              name: "Ipswich",
            },
            {
              id: 6,
              name: "Mackay",
            },
            {
              id: 7,
              name: "Rockhampton",
            },
            {
              id: 8,
              name: "Sunshine Coast",
            },
            {
              id: 9,
              name: "Toowoomba",
            },
            {
              id: 10,
              name: "Townsville",
            },
            {
              id: 11,
              name: "Bundaberg",
            },
            {
              id: 12,
              name: "Gladstone",
            },
            {
              id: 13,
              name: "Emerald",
            },
            {
              id: 14,
              name: "Mount Isa",
            },
          ],
        },
        {
          name: "South Australia",
          id: 5,
          show: false,
          children: [
            {
              id: 1,
              name: "Adelaide",
            },
            {
              id: 2,
              name: "Gawler",
            },
            {
              id: 3,
              name: "Goolwa",
            },
            {
              id: 4,
              name: "Millicent",
            },
            {
              id: 5,
              name: "Mount Barker",
            },
            {
              id: 6,
              name: "Mount Gambier",
            },
            {
              id: 7,
              name: "Murray Bridge",
            },
            {
              id: 8,
              name: "Nairne",
            },
            {
              id: 9,
              name: "Nuriootpa",
            },
            {
              id: 10,
              name: "Port Augusta",
            },
            {
              id: 11,
              name: "Port Lincoln",
            },
            {
              id: 12,
              name: "Port Pirie",
            },
            {
              id: 13,
              name: "Strathalbyn",
            },
            {
              id: 14,
              name: "Victor Harbor",
            },
            {
              id: 15,
              name: "Whyalla",
            },
          ],
        },
        {
          name: "Tasmania",
          id: 6,
          show: false,
          children: [
            {
              id: 1,
              name: "Blackmans Bay",
            },
            {
              id: 2,
              name: "Bridgewater",
            },
            {
              id: 3,
              name: "Burnie",
            },
            {
              id: 4,
              name: "Devonport",
            },
            {
              id: 5,
              name: "Gagebrook",
            },
            {
              id: 6,
              name: "George Town",
            },
            {
              id: 7,
              name: "Hobart",
            },
            {
              id: 8,
              name: "Kingston",
            },
            {
              id: 9,
              name: "Launceston",
            },
            {
              id: 10,
              name: "Longford",
            },
            {
              id: 11,
              name: "New Norfolk",
            },
            {
              id: 12,
              name: "Smithton",
            },
            {
              id: 13,
              name: "Somerset",
            },
            {
              id: 14,
              name: "Ulverstone",
            },
            {
              id: 15,
              name: "Wynyard",
            },
          ],
        },
        {
          name: "Victoria",
          id: 7,
          show: false,
          children: [
            {
              id: 1,
              name: "Ballarat",
            },
            {
              id: 2,
              name: "Bendigo",
            },
            {
              id: 3,
              name: "Geelong",
            },
            {
              id: 4,
              name: "Melbourne",
            },
            {
              id: 5,
              name: "Melton",
            },
            {
              id: 6,
              name: "Mildura",
            },
            {
              id: 7,
              name: "Ocean Grove",
            },
            {
              id: 8,
              name: "Pakenham",
            },
            {
              id: 9,
              name: "Shepparton",
            },
            {
              id: 10,
              name: "Sunbury",
            },
            {
              id: 11,
              name: "Torquay",
            },
            {
              id: 12,
              name: "Traralgon",
            },
            {
              id: 13,
              name: "Wangaratta",
            },
            {
              id: 14,
              name: "Warrnambool",
            },
            {
              id: 15,
              name: "Wodonga",
            },
          ],
        },
        {
          name: "Western Australia",
          id: 8,
          show: false,
          children: [
            {
              id: 1,
              name: "Albany",
            },
            {
              id: 2,
              name: "Broome",
            },
            {
              id: 3,
              name: "Bunbury",
            },
            {
              id: 4,
              name: "Busselton",
            },
            {
              id: 5,
              name: "Collie",
            },
            {
              id: 6,
              name: "Dunsborough",
            },
            {
              id: 7,
              name: "Esperance",
            },
            {
              id: 8,
              name: "Geraldton",
            },
            {
              id: 9,
              name: "Kalgoorlie-Boulder",
            },
            {
              id: 10,
              name: "Karratha",
            },
            {
              id: 11,
              name: "Margaret River",
            },
            {
              id: 12,
              name: "Northan",
            },
            {
              id: 13,
              name: "Perth",
            },
            {
              id: 14,
              name: "Port Hedland",
            },
            {
              id: 15,
              name: "Yanchep",
            },
          ],
        },
      ],
      locationList1: [
        {
          name: "Sell Car Brisbane",
          id: 1,
          show: false,
        },
        {
          name: "Sell Car Online",
          id: 2,
          show: false,
        },
        {
          name: "Sell Used Car",
          id: 3,
          show: false,
        },
        {
          name: "Sell Car for Cash",
          id: 4,
          show: false,
        },
      ],
      moveIndex: "",
      currentIndex: "",
      sideShow: false,
      locationShow: false,
      locationShow1: false,
      moveIndex1: "",
    };
  },
  methods: {
    itemMouseOut() {
      this.moveIndex = "";
    },
    itemMouseOut1() {
      this.moveIndex1 = "";
    },
    // 鼠标移入
    itemMouseMove(index) {
      this.moveIndex = index;
    },
    itemMouseMove1(index) {
      this.moveIndex1 = index;
    },
    navClick(index) {
      this.currentIndex = index;
      if (index === 0) {
        this.$router.push({
          name: "Kong",
          query: { path: "/location-sell-your-car", index: 0 },
        });
      } else if (index === 1) {
        this.$router.push({ name: "About", query: { index: 1 } });
      } else if (index === 2) {
        this.$router.push({ name: "FAQ", query: { index: 2 } });
      } else if (index === 4) {
        this.$router.push({ name: "FAQ", query: { index: 4 } });
      } else if (index === 5) {
        this.$router.push({ name: "Blogs", query: { index: 5 } });
      } else {
        this.$router.push({
          name: "Kong",
          query: { path: "/start-sell-your-car", index: 3 },
        });
      }
    },
    goHome() {
      this.$router.push({ name: "Kong", query: { path: "/" } });
    },
    goPage(row, id, cId) {
      this.$router.push({
        name: "Kong",
        query: {
          path: "/location-sell-your-car",
          index: 0,
          id,
          cId,
          name: row.name,
        },
      });
    },
    goPage1(row, id, cId) {
      if (id == 1) {
        this.$router.push({
          name: "Kong",
          query: {
            path: "/sell-my-car-brisbane",
            index: 4,
            id,
            cId,
            name: row.name,
          },
        });
      }
      if (id == 2) {
        this.$router.push({
          name: "Kong",
          query: {
            path: "/sell-car-online",
            index: 4,
            id,
            cId,
            name: row.name,
          },
        });
      }
      if (id == 3) {
        this.$router.push({
          name: "Kong",
          query: {
            path: "/sell-used-car",
            index: 4,
            id,
            cId,
            name: row.name,
          },
        });
      }
      if (id == 4) {
        this.$router.push({
          name: "Kong",
          query: {
            path: "/sell-car-cash",
            index: 4,
            id,
            cId,
            name: row.name,
          },
        });
      }
    },
    goStart() {
      this.$router.push({
        name: "Kong",
        query: { path: "/start-sell-your-car" },
      });
    },
    handleLocation() {
      if (!this.locationShow) {
        this.locationShow = true;
        this.$refs.sub.style.display = "block";
      } else {
        this.locationShow = false;
        this.$refs.sub.style.display = "none";
      }
    },
    handleLocation1() {
      if (!this.locationShow1) {
        this.locationShow1 = true;
        this.$refs.sub1.style.display = "block";
      } else {
        this.locationShow1 = false;
        this.$refs.sub1.style.display = "none";
      }
    },
    itemClick(index) {
      if (!this.locationList[index].show) {
        this.moveIndex = index;
        this.locationList[index].show = true;
      } else {
        this.moveIndex = "";
        this.locationList[index].show = false;
      }
    },
    handleSide() {
      this.sideShow = !this.sideShow;
      if (this.sideShow) {
        this.$refs.nav.style.display = "block";
      } else {
        this.$refs.nav.style.display = "none";
      }
    },
    close() {
      this.$refs.nav.style.display = "none";
      this.sideShow = false;
    },
  },
  created() {
    if (this.$route.query.index || this.$route.query.index === 0) {
      this.currentIndex = this.$route.query.index * 1;
    }
  },
};
</script>

<style scoped lang="less">
.header {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  background: rgba(255, 255, 255, 0.92);
  filter: blur(undefinedpx);
  z-index: 9999;

  .header-warpper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .left {
      display: flex;
      align-items: center;
      height: 100%;

      .logo {
        width: 220px;
        height: 80%;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;

        img {
          width: 67%;
          height: 86%;
        }
      }

      .nav-warpper {
        height: 100%;

        .nav {
          height: 100%;
          display: flex;
          align-items: center;

          .nav-item {
            height: 100%;
            padding: 0 20px;
            box-sizing: border-box;
            margin-left: 10px;
            font-family: Ubuntu-Bold, Ubuntu;
            font-weight: bold;
            color: #062748;
            line-height: 21px;
            cursor: pointer;
            display: flex;
            align-items: center;

            .location {
              display: flex;
              align-items: center;

              img {
                width: 16px;
                height: 16px;
              }

              .img {
                display: none;
              }
            }

            &:first-child {
              display: none;
            }

            &:nth-child(2),
            &:nth-child(5) {
              position: relative;

              &:hover .sub-menu {
                display: block;
              }

              .sub-menu {
                position: absolute;
                top: 80px;
                left: -50%;
                border: 1px solid #e9e9e9;
                background-color: rgba(255, 255, 255, 0.92);
                padding: 20px;
                width: 240px;
                box-sizing: border-box;
                display: none;

                .sub-menu-item {
                  width: 100%;
                  height: 40px;
                  cursor: pointer;

                  .label {
                    height: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .img {
                      display: none;
                    }
                  }

                  &:hover {
                    background: #062748;
                    color: #fff;

                    & {
                      .fa-angle-right {
                        color: #fff;
                      }
                    }
                  }

                  .sub-sub-menu {
                    position: absolute;
                    border: 1px solid #e9e9e9;
                    background-color: rgba(255, 255, 255, 0.92);
                    padding: 20px;
                    width: 240px;
                    left: 239px;
                    top: -1px;
                    box-sizing: border-box;

                    .sub-sub-menu-item {
                      display: flex;
                      align-items: center;
                      width: 100%;
                      height: 40px;
                      cursor: pointer;
                      color: #062748;

                      &:hover {
                        background: #062748;
                        color: #fff;
                      }
                    }

                    .current {
                      background: #062748;
                      color: #fff;
                    }
                  }
                }

                .current {
                  background: #062748;
                  color: #fff;
                }
              }
            }

            &:nth-child(7) {
              display: none;
            }

            &:nth-child(8) {
              display: none;
            }
          }

          .current {
            .info {
              color: #54cbff;
            }
          }
        }
      }

      .phone {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-family: Ubuntu-Regular, Ubuntu;
        font-weight: 600;
        color: #062748;
        line-height: 21px;
        cursor: pointer;
        margin-left: 32px;

        img {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
      }
    }

    .right {
      // width: 160px;
      // height: 40px;
      background: #0c61b9;
      border-radius: 8px 8px 8px 8px;
      padding: 12px 32px;
      box-sizing: border-box;
      font-family: Ubuntu-Bold, Ubuntu;
      font-weight: bold;
      color: #ffffff;
      line-height: 16px;
      cursor: pointer;
    }

    .right-button {
      display: none;
    }
  }
}

@media only screen and (max-width: 690px) {
  .header {
    width: 100%;
    height: 64px;
    padding: 12px 20px;
    box-sizing: border-box;

    .header-warpper {
      .left {
        position: relative;

        .logo {
          width: 160px;
          height: 100%;
          img {
          width: 67%;
          height: 100%;
        }
        }

        .nav-warpper {
          display: none;
          position: fixed;
          left: 0;
          top: 0;
          right: 0;
          background: rgba(0, 0, 0, 0.5);

          .nav {
            background: #fff;
            flex-direction: column;
            width: 280px;
            align-items: flex-start;
            overflow: auto;

            .nav-item {
              width: 280px;
              padding: 0 20px;
              box-sizing: border-box;
              margin-left: 0;
              font-size: 18px;
              line-height: 19px;
              height: auto;
              display: block;
              color: #222222;
              margin-top: 20px;

              .location {
                width: 100%;
                justify-content: space-between;

                img {
                  display: none;
                }

                .img {
                  display: block;
                  width: 16px;
                  height: 16px;
                }
              }

              &:first-child {
                display: block;
                padding: 12px 20px;
                box-sizing: border-box;
                margin-top: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #eaeaea;

                .logo {
                  width: 136.5px;
                  height: 40px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

                .close {
                  width: 40px;
                  height: 40px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }

              &:nth-child(2),
              &:nth-child(5) {
                &:hover .sub-menu {
                  display: none;
                }

                .sub-menu {
                  position: static;
                  display: none;
                  border: none;
                  font-size: 14px;
                  padding: 0 0 0 20px;
                  color: #222222;

                  .sub-menu-item {
                    height: auto;
                    margin-top: 20px;

                    .label {
                      .sf-sub-indicator {
                        display: none;
                      }

                      .img {
                        display: block;
                        width: 16px;
                        height: 16px;
                      }
                    }

                    &:hover {
                      background: #fff;
                      color: #222;
                    }

                    .sub-sub-menu {
                      position: static;
                      border: none;
                      font-size: 14px;
                      padding: 0 0 0 20px;
                      font-family: Ubuntu-Regular;
                      font-weight: 400;
                      background: #fff;

                      .sub-sub-menu-item {
                        color: #222;

                        &:hover {
                          background: #fff;
                          color: #222;
                        }
                      }

                      .current {
                        background: #fff;
                        color: #54cbff;
                      }
                    }
                  }

                  .current {
                    background: #fff;
                    color: #54cbff;
                  }
                }
              }

              &:nth-child(5) {
                display: block;
              }

              &:nth-child(6) {
                display: block;
              }
            }
          }
        }

        .phone {
          // display: none;
          margin-left: 0;
        }
      }

      .right {
        display: none;
      }

      .right-button {
        display: block;
        width: 22px;
        height: 22px;
        display: flex;
        align-content: space-around;
        flex-wrap: wrap;

        .line {
          width: 100%;
          height: 2px;
          background: #062748;
        }
      }
    }
  }
}

@media only screen and (max-width: 1050px) and (min-width: 690px) {
  .header {
    width: 100%;
    height: 64px;
    padding: 12px 20px;
    box-sizing: border-box;

    .header-warpper {
      .left {
        position: relative;

        .logo {
          width: 136.5px;
          height: 100%;
          img {
          width: 67%;
          height: 100%;
        }
        }

        .nav-warpper {
          display: none;
          position: fixed;
          left: 0;
          top: 0;
          right: 0;
          background: rgba(0, 0, 0, 0.5);

          .nav {
            background: #fff;
            flex-direction: column;
            width: 280px;
            align-items: flex-start;
            overflow: auto;

            .nav-item {
              width: 280px;
              padding: 0 20px;
              box-sizing: border-box;
              margin-left: 0;
              font-size: 18px;
              line-height: 19px;
              height: auto;
              display: block;
              color: #222222;
              margin-top: 20px;

              .location {
                width: 100%;
                justify-content: space-between;

                img {
                  display: none;
                }

                .img {
                  display: block;
                  width: 16px;
                  height: 16px;
                }
              }

              &:first-child {
                display: block;
                padding: 12px 20px;
                box-sizing: border-box;
                margin-top: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #eaeaea;

                .logo {
                  width: 136.5px;
                  height: 40px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

                .close {
                  width: 40px;
                  height: 40px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }

              &:nth-child(2),
              &:nth-child(5) {
                &:hover .sub-menu {
                  display: none;
                }

                .sub-menu {
                  position: static;
                  display: none;
                  border: none;
                  font-size: 14px;
                  padding: 0 0 0 20px;
                  color: #222222;

                  .sub-menu-item {
                    height: auto;
                    margin-top: 20px;

                    .label {
                      .sf-sub-indicator {
                        display: none;
                      }

                      .img {
                        display: block;
                        width: 16px;
                        height: 16px;
                      }
                    }

                    &:hover {
                      background: #fff;
                      color: #222;
                    }

                    .sub-sub-menu {
                      position: static;
                      border: none;
                      font-size: 14px;
                      padding: 0 0 0 20px;
                      font-family: Ubuntu-Regular;
                      font-weight: 400;
                      background: #fff;

                      .sub-sub-menu-item {
                        color: #222;

                        &:hover {
                          background: #fff;
                          color: #222;
                        }
                      }

                      .current {
                        background: #fff;
                        color: #54cbff;
                      }
                    }
                  }

                  .current {
                    background: #fff;
                    color: #54cbff;
                  }
                }
              }

              &:nth-child(5) {
                display: block;
              }

              &:nth-child(6) {
                display: block;
              }
            }
          }
        }

        .phone {
          display: none;
        }
      }

      .right {
        display: none;
      }

      .right-button {
        display: block;
        width: 22px;
        height: 22px;
        display: flex;
        align-content: space-around;
        flex-wrap: wrap;

        .line {
          width: 100%;
          height: 2px;
          background: #062748;
        }
      }
    }
  }
}
</style>
